export const CAL_PLUS_FEATURES = {
  customizedScreensaver: {
    heading: 'Photo Screensaver',
    text: `Bring a smile to each day – show beautiful family photos and videos when your Calendar is not in use.`,
  },
  mealPlanning: {
    heading: 'Meal Planning',
    text: `The answer to “what’s for dinner?” Plan meals from your own recipe box, or let Sidekick magically add new ones based on your diet & tastes.`,
  },
  sidekick: {
    heading: 'Sidekick AI',
    text: `Save time with an AI assistant that magically imports recipes, plans meals, creates grocery lists, adds events from PDFs, and more!`,
  },
  rewards: {
    heading: 'Chore Rewards',
    text: `Assign stars to chores that unlock rewards, motivate kids to complete tasks, and build healthy habits.`,
  },
}
export const PRODUCT_DROPDOWN_LABELS = {
  27: {
    label: `27" Calendar Max`,
  },
  15: {
    label: `15" Calendar`,
  },
  10: {
    label: `10" Calendar`,
  },
}
